import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import logo from "../../assets/css/images/foliologo.png"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import picture from "../../images/enterprise-security-large.jpg"

export default () => (
  <Layout isHome={false} pageId="folio_single_item" logo={logo}>
    <SEO title="Enterprise Security" />
    <div id="content">
      <div id="about_project">
        <h2 className="folio-title">Enterprise Security</h2>
        <div className="contact-intro">
          The challenges to ensuring the security, privacy, and integrity of
          technology systems have never been greater. A growing reliance on
          networked computers has given rise to greater vulnerability and
          created formidable new threats.
        </div>

        <h5>Privacy &amp; security of your business</h5>
        <p>
          Information security requires protecting computers, software, and
          information systems from theft, damage, or disruption — no matter the
          cause. Security means protection from natural disasters, as well as
          bad actors. At GetFresh Software, we help organizations harden their
          infrastructure and software applications against any threat.
        </p>
        <h5>Practice security</h5>
        <p>
          We take seriously our responsibility to safeguard the systems and data
          our clients entrust to us. We continually train our staff on evolving
          security threats, procedures, and safeguards. From years of experience
          in security conscious industries — ecommerce, healthcare, and finance
          — we’re familiar with the special handling required for sensitive
          information. This familiarity is rooted in our work creating PCI DSS
          compliant systems for ecommerce and payment processing and HIPAA
          compliant software for the healthcare industry. Everybody likes to say
          that their system administrators stay informed of emerging security
          vulnerabilities and apply software patches. Ours really do.
        </p>
        <ul className="navig">
          <li className="prev">
            <AniLink fade to="/solutions/managed-infrastructure" rel="prev">
              Managed Infrastructure
            </AniLink>
          </li>
          <li className="next">
            {/* <AniLink fade to="/solutions/managed-infrastructure" rel="next">
              Managed Infrastructure
            </AniLink> */}
          </li>
        </ul>
      </div>

      <div id="preview">
        <div className="blogimg slide image_1 current">
          <img
            src={picture}
            className="attachment-large-folio-thumb size-large-folio-thumb"
            alt="Enterprise Security"
          />
        </div>
        <h5>Security &amp; risk assessment services</h5>
        <p>
          Audit to identify infrastructure security risks, including software,
          personnel and operational practices.
          <br />
          Develop a plan to protect infrastructure, systems and data.
          <br />
          Harden infrastructure and software applications.
          <br />
          Deploy real-time monitoring and recovery systems.
          <br />
          Respond to threats and plan for continuous improvement.
        </p>
      </div>
    </div>
  </Layout>
)
